@font-face {
  font-family: "iconfont"; /* Project id 4674337 */
  src: url('iconfont.woff2?t=1726302285735') format('woff2'),
       url('iconfont.woff?t=1726302285735') format('woff'),
       url('iconfont.ttf?t=1726302285735') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-LogIcon:before {
  content: "\e652";
}

.icon-IconMenuAttendance:before {
  content: "\e651";
}

.icon-a-ic_uploadtransactions:before {
  content: "\e650";
}

.icon-add_use:before {
  content: "\e64e";
}

.icon-operation:before {
  content: "\e64f";
}

.icon-detail:before {
  content: "\e64d";
}

.icon-ic_more:before {
  content: "\e64c";
}

.icon-a-ic_daylightsavingtime:before {
  content: "\e64b";
}

.icon-yonghu:before {
  content: "\e6d5";
}

.icon-youxiang:before {
  content: "\e667";
}

.icon-mima:before {
  content: "\e659";
}

.icon-icon_duihao-mian:before {
  content: "\e64a";
}

.icon-ic_card:before {
  content: "\e647";
}

.icon-ic_fingerprint:before {
  content: "\e648";
}

.icon-ic_password:before {
  content: "\e649";
}

.icon-ic_face:before {
  content: "\e646";
}

.icon-ic_close:before {
  content: "\e645";
}

.icon-ic_open:before {
  content: "\e644";
}

.icon-ic_collect:before {
  content: "\e643";
}

.icon-ic_search:before {
  content: "\e642";
}

.icon-ic_download:before {
  content: "\e640";
}

.icon-ic_export:before {
  content: "\e641";
}

.icon-ic_syncperson:before {
  content: "\e63f";
}

.icon-ic_view:before {
  content: "\e63e";
}

.icon-ic_calendar:before {
  content: "\e63d";
}

.icon-ic_personnel:before {
  content: "\e63c";
}

.icon-ic_edit:before {
  content: "\e639";
}

.icon-ic_markasresigned:before {
  content: "\e63a";
}

.icon-ic_delete:before {
  content: "\e63b";
}

.icon-ic_add:before {
  content: "\e638";
}

.icon-ic_language:before {
  content: "\e635";
}

.icon-ic_news:before {
  content: "\e636";
}

.icon-ic_help:before {
  content: "\e637";
}

.icon-ic_about:before {
  content: "\e631";
}

.icon-ic_data:before {
  content: "\e632";
}

.icon-ic_contactus:before {
  content: "\e633";
}

.icon-ic_resetpassword:before {
  content: "\e634";
}

.icon-ic_logout:before {
  content: "\e630";
}

.icon-logo:before {
  content: "\e62f";
}

.icon-ic_refresh:before {
  content: "\e62e";
}

.icon-ic_menu_organization:before {
  content: "\e62d";
}

.icon-ic_menu_device:before {
  content: "\e62c";
}

.icon-ic_menu_setting:before {
  content: "\e628";
}

.icon-ic_menu_attendance:before {
  content: "\e629";
}

.icon-ic_menu_dashboard:before {
  content: "\e62a";
}

.icon-ic_menu_quicksettings:before {
  content: "\e62b";
}

