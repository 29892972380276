.toolbar-container
  display: flex
  flex-direction: row
  align-items: center
  background-color: #f7f6fd

.MuiBreadcrumbs-root
  .MuiTypography-body1
    font-size: 20px
    font-weight: 500
    color: #304669

.MuiBreadcrumbs-root
    #primaryTitle
      color: #2BA9DE

.MuiBreadcrumbs-separator
  margin-left: 4px
  margin-right: 4px

.MuiInputLabel-asterisk
  color: #d32f2f !important
  order: 1

input::-ms-reveal, input::-ms-clear
  display: none

.MuiDataGrid-filterFormValueInput
  width: 250px !important
