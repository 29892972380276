::-webkit-scrollbar {
  width: 6px; /* 设置滚动条的宽度 */
  height: 6px;
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 设置轨道的背景颜色 */
}

/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: #888; /* 设置滑块的背景颜色 */
}

/* 当滑块悬停或活动时自定义滚动条的滑块 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 设置滑块在悬停状态下的背景颜色 */
}

.MuiDropzoneArea-root.supportDropzone .MuiDropzoneArea-text{
  position: absolute;
  left: 50%;
  width: 100%;
  margin-left: -50%;
  bottom: 20px;
}
